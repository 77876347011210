import React from 'react'
import {Link} from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'

const OurSolutions = () => {
    return (
        <section className="solutions-area pb-70">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="star" /> 
                        Our Solutions
                    </span>
                    <h2>Taking initiatives in the new era of digital revolution</h2>
                    <p>We offer reliable and up-to date solutions to lead the e-commerce driven new era of digital revolution.</p>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-rocket"></i>
                            </div>
                            <h3>
                                <Link to="/service-details">
                                    ERP Solutions
                                </Link>
                            </h3>
                            <p>
                            Managing all the core supply chain, manufacturing, services, financial and other processes of an organization.
                            </p>

                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-laptop"></i>
                            </div>

                            <h3>
                                <Link to="/service-details">
                                    Logistics Platform
                                </Link>
                            </h3>

                            <p>
                                We modernize global logistics. Find everything you need to optimize your supply chain on our platform.
                            </p>
                            
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-money"></i>
                            </div>

                            <h3>
                                <Link to="/service-details">
                                    eCommerce Integrations
                                </Link>
                            </h3> 

                            <p>Scale your e-commerce business with various integration solutions. Amazon, Shopify or B2B on your way.</p>
                            
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurSolutions;