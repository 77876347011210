import React from 'react';
import {Link} from 'gatsby'

import MapShape2 from '../../assets/images/shape/map-shape2.png'
import Partner1 from '../../assets/images/partner/logo_iottie.png'
import Partner2 from '../../assets/images/partner/logo_pnpline.png'
import Partner3 from '../../assets/images/partner/logo_duogreen.png'
import Partner4 from '../../assets/images/partner/logo_worldshoppe.png'
import Partner5 from '../../assets/images/partner/logo_gc.png'
import Partner6 from '../../assets/images/partner/logo_avarelle.png'

const WhyChooseUs = () => {
    return (
        <section className="why-choose-us-area bg-color">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-12">
                        <div className="why-choose-us-content">
                            <h2>Why Choose Us?</h2>
                            <p>With those partners, we have built up comprehensive competencies that cover e-commerce, data management, b2b edi integrations, business applications (including in-house ERP), and logistics platform.</p>
                            <p>In 2020, we were renewed our team as an IT Company, as well as located on Fullerton, CA to meet fast growing market requiremtes on e-business.</p>
                            
                            <Link to="/about-us" className="default-btn">
                                <i className="flaticon-right"></i> 
                                More About Us <span></span>
                            </Link>

                            <div className="map-shape2">
                                <img src={MapShape2} alt="MapShape" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="our-brand-partners-list">
                            <h2>Our Brand Partners</h2>
                            <div className="row align-items-center">
                                <div className="col-lg-6 col-sm-6 col-md-4">
                                    <img src={Partner1} alt="Partner" style={{width:"70%"}}/>
                                </div>

                                <div className="col-lg-6 col-sm-6 col-md-4">
                                    <img src={Partner2} alt="Partner" style={{width:"70%"}}/>
                                </div>

                                <div className="col-lg-6 col-sm-6 col-md-4">
                                    <img src={Partner3} alt="Partner" style={{width:"70%"}}/>
                                </div>
                                
                                <div className="col-lg-6 col-sm-6 col-md-4">
                                    <img src={Partner4} alt="Partner" style={{width:"70%"}}/>
                                </div>

                                <div className="col-lg-6 col-sm-6 col-md-4">
                                    <img src={Partner5} alt="Partner" style={{width:"70%"}}/>
                                </div>
                                
                                <div className="col-lg-6 col-sm-6 col-md-4">
                                    <img src={Partner6} alt="Partner" style={{width:"70%"}}/>
                                </div>
                            </div>

                            {/*<Link to="/partner" className="view-all">
                                View All
    </Link>*/}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default WhyChooseUs;